import '../sass/main.scss'
import "core-js/stable";
import "regenerator-runtime/runtime";
import { gsapAnimations } from "./gsap-animations.js"


// Add class when JavaScript doesn't work in user's browser
document.querySelector("html").classList.remove("no-js")

document.addEventListener("DOMContentLoaded", () => {
  document.body.classList.remove('hidden');
  document.body.classList.add('visible');

  gsapAnimations()
  
  // ————————————————————————————————————————————————————————————
  // LOAD MODULES
  // ————————————————————————————————————————————————————————————
  
})

export const gsapAnimations = () => {
  gsap.registerPlugin(ScrollTrigger);

  if (document.querySelector('.animation')) {

    gsap.from('.home-hero-animation', {
      opacity: 0,
      scale: 1.2,
      duration: 1.6,
      ease: 'power1'
    })

    gsap.from('.page-hero-animation', {
      opacity: 0,
      scale: 1.2,
      duration: 1.6,
      ease: 'power1'
    })

    gsap.from('.image-text-animation', {
      x: -50,
      opacity: 0,
      duration: 0.5,
      ease: 'power1',
      scrollTrigger: {
        trigger: '#image-text',
        start: 'center bottom',
      }
    })

    gsap.from('.content-utensils-animation', {
      x: -50,
      opacity: 0,
      duration: 0.5,
      ease: 'power1',
      scrollTrigger: {
        trigger: '#content-utensils',
        start: 'center bottom',
      }
    })

    gsap.from('.photos-animation', {
      x: -50,
      opacity: 0,
      duration: 0.5,
      ease: 'power1',
      stagger: 0.3,
      scrollTrigger: {
        trigger: '#photos',
        start: 'center bottom',
      }
    })

    gsap.from('.text-right-animation', {
      x: -50,
      opacity: 0,
      duration: 0.5,
      ease: 'power1',
      scrollTrigger: {
        trigger: '#join',
        start: 'center bottom',
      }
    })

    gsap.from('.text-map-animation', {
      x: -50,
      opacity: 0,
      duration: 0.5,
      ease: 'power1',
      scrollTrigger: {
        trigger: '#text-map',
        start: 'center bottom',
      }
    })

    gsap.from('.text-bullets-animation', {
      x: -50,
      opacity: 0,
      duration: 0.5,
      ease: 'power1',
      stagger: 0.3,
      scrollTrigger: {
        trigger: '#text-bullets',
        start: 'center bottom',
      }
    })

    gsap.from('.text-bird-green-animation', {
      x: -50,
      opacity: 0,
      duration: 0.5,
      ease: 'power1',
      scrollTrigger: {
        trigger: '#text-bird-green',
        start: 'center bottom',
      }
    })
  }
}
